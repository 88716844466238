import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { selectGeneralInfo } from '../../../selectors/accountSettings';
import {
  formatBidDueDate,
  formatDate,
  formatDateMMDDYY,
  formatDateObject,
} from '../../../utils/date-formatters';
import styles from './style.module.scss';
import { isAllowed, moduleConstants } from '../../../_constants';
import { formatMoney,formatPercent } from '../../../utils/number-formatters';

import { Link } from 'react-router-dom';

function DatesAndDollars(props) {
  const [state, setState] = useState({
    userId: localStorage.getItem('userId'),
    lienPeriod: localStorage.getItem('lienPeriod') || 0,
    objectId: null,
    accountId: localStorage.getItem('accountId'),
    projectData: {},
    accountInfo: {},
    submitted: false,
  });

  useEffect(() => {
    setState((prevState) => ({ ...prevState, accountInfo: props.accountInfo }));
  }, [props.accountInfo]);

  useEffect(() => {
    if (props.projectData && props.projectData.objectId) {
      setState((prevState) => ({
        ...prevState,
        objectId: props.projectData.objectId,
        projectData: props.projectData,
      }));
    } else if (props.projectData && props.projectData.projectId) {
      setState((prevState) => ({
        ...prevState,
        objectId: props.projectData.projectId.objectId,
        projectData: props.projectData,
      }));
    }
  }, [props.projectData]);

  function varianceForDollars(value1, value2) {
    const difference = value1 - value2;
    const differenceModulus = -difference;

    return (
      difference < 0
        ? <p style={{ color: 'red' }}>{`(${formatMoney(differenceModulus)})`}</p>
        : <p>{formatMoney(difference)}</p>
    );
  }

  function varianceForRedDates(value) {
    return (
      value > 0
        ? <p style={{ color: 'red' }}>{`${value}d behind`}</p>
        : value < 0
        ? <p >{`${-value}d ahead`}</p>
        : <p>{value}</p>
    );
  }

  function daysBetweenDates(date1, date2) {
    const dateObject1 = formatDateObject(date1);
    const dateObject2 = formatDateObject(date2);
    return dateObject1.diff(dateObject2, 'days');
  }

  function closeoutDateValue(projectData) {
    if (projectData?.closeoutDate?.iso) {
      if (projectData?.deliveredDate?.iso) {
        return varianceForRedDates(
          daysBetweenDates(projectData.deliveredDate.iso, projectData.closeoutDate.iso)
        );
      }

      if (projectData?.endDate?.iso && projectData?.closeoutDate?.iso) {
        return varianceForRedDates(
          daysBetweenDates(projectData.endDate.iso, projectData.closeoutDate.iso)
        );
      }
    }

    return '-';
  }

  const { projectData, accountInfo } = state;

  const isWarrantyService = !!projectData.isWarrantyService;

  const isInWarranty = projectData.jobStatusCodesCode === 'W';

  const isServiceProject = ['SVC', 'SVC-D', 'SVC-C'].includes(projectData.jobStatusCodesCode);

  const isDeliveredVisible = ['D', 'W', 'C'].includes(projectData.jobStatusCodesCode);

  const isActivePlusStage =
    ['A', 'SNS', 'SUSP', 'D', 'W', 'C'].includes(projectData.jobStatusCodesCode);

  const isBidDueVisible =
    ['B', 'BP', 'P1', 'P2', 'P3', 'P1P', 'P2P', 'P3P', 'OH', 'OHP', 'PC', 'R', 'XC', 'NC']
      .includes(projectData.jobStatusCodesCode);

  const isBidSubmittedVisible =
    ['BP', 'P1P', 'P2P', 'P3P', 'OHP', 'PC', 'R', 'XC'].includes(projectData.jobStatusCodesCode);

  const tableClassName = isActivePlusStage ? styles.activePlusStageTable : '';

  const isCreator = projectData?.createdBy?.objectId === state.userId;
  const canViewProfit =
    isAllowed(moduleConstants.VIEWPROFIT, isCreator ? 'anyAssigned' : 'assigned', projectData?.userAssignData)
  const canViewContract =
    isAllowed(moduleConstants.VIEWPROFIT, isCreator ? 'anyAssigned' : 'assigned', projectData?.userAssignData);

  const canEditBid = isAllowed(moduleConstants.EBAGP, isCreator ? 'anyAssigned' : 'assigned', projectData?.userAssignData);
  const canEditWIP = isAllowed(moduleConstants.EW, isCreator ? 'anyAssigned' : 'assigned', projectData?.userAssignData);

  const qbSyncData = projectData?.qbSyncData || {};

  const renderQBdata = (textonly = false) => {
    // qb sync data has costToDate, invoiceToDate, estimateIncome, estimateCost, and outstanging

    if(!qbSyncData.costToDate && !qbSyncData.invoiceToDate && !qbSyncData.estimateIncome && !qbSyncData.estimateCost && !qbSyncData.outstanding) {
      return;
    }

    if(!textonly) {
      
        const maxValue = Math.max(qbSyncData.estimateIncome, qbSyncData.estimateCost, qbSyncData.invoiceToDate, qbSyncData.costToDate);
        const getBarWidth = (value) => (value / maxValue) * 100;

        // if not delivered, days elapsed is today - start date else delivered date - start date
        const daysElapsed = projectData.deliveredDate ? daysBetweenDates(projectData.deliveredDate.iso, projectData.startDate.iso) : daysBetweenDates(new Date().toISOString(), projectData.startDate.iso);
        const projectDuration = daysBetweenDates(projectData.endDate.iso, projectData.startDate.iso);
      
        const errors = [];
        if (qbSyncData.outstanding > 0) {
          errors.push(`Overdue invoices: ${formatMoney(qbSyncData.outstanding)}`);
        }
        if (qbSyncData.estimateIncome > qbSyncData.invoiceToDate) {
          errors.push(`Unbilled amount: ${formatMoney(qbSyncData.estimateIncome - qbSyncData.invoiceToDate)}`);
        }
        if (qbSyncData.estimateCost > qbSyncData.costToDate && qbSyncData.invoiceToDate < qbSyncData.estimateIncome) {
          errors.push('Cost to complete is higher than time to complete');
        }
      
        return (
          <div className='pDetailTableBox compactTable'>
            <table className={tableClassName}>
              <tbody>
                <tr>
                  <td className='compactHeader'>
                    <strong>QuickBooks</strong>
                  </td>
                  <td><strong>Actual</strong></td>
                  <td>  </td>
                  <td><strong>Contract</strong></td>
                  {errors.length > 0 && <td rowSpan="4" className={styles.errorColumn}>
                    {errors.map((error, index) => (
                      <div key={index} className={styles.error}>{error}</div>
                    ))}
                  </td>}
                </tr>
                <tr>
                  <td className='compactHeader'>Income</td>
                  
                  <td>{formatMoney(qbSyncData.invoiceToDate)}</td>
                  <td>
                    <div className={styles.barContainer}>
                      <div 
                        className={styles.bar} 
                        style={{width: `${(qbSyncData.invoiceToDate/qbSyncData.estimateIncome*100)}%`, backgroundColor: 'green'}}
                      ></div>
                    </div>
                  </td>
                  <td>{formatMoney(qbSyncData.estimateIncome)}</td>
                </tr>
                <tr>
                  <td className='compactHeader'>Costs</td>
                  
                  <td>{formatMoney(qbSyncData.costToDate)}</td>
                  <td>
                    <div className={styles.barContainer}>
                      <div 
                        className={styles.bar} 
                        style={{width: `${(qbSyncData.costToDate/qbSyncData.estimateCost*100)}%`, backgroundColor: 'blue'}}
                      ></div>
                    </div>
                  </td>
                  <td>{formatMoney(qbSyncData.estimateCost)}</td>
                </tr>
                <tr>
                  <td className='compactHeader'>Time</td>
                  
                  <td>{daysElapsed} days</td>
                  <td>
                    <div className={styles.barContainer}>
                      <div 
                        className={styles.bar} 
                        style={{width: `${(daysElapsed / projectDuration) * 100}%`, backgroundColor: 'orange'}}
                      ></div>
                    </div>
                  </td>
                  <td>{projectDuration} days ({projectDuration - daysElapsed} days left)</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      }
    

    return (
      <div className='pDetailTableBox compactTable'>
        <table className={tableClassName}>
          <tbody>
            <tr>
              <td className='compactHeader'>
                <strong>QuickBooks</strong>
              </td>
              <td><strong>Cost to Date</strong></td>
              <td><strong>Invoice to Date</strong></td>
              <td><strong>Est. Income</strong></td>
              <td><strong>Est. Cost</strong></td>
              <td><strong>Outstanding</strong></td>
            </tr>
            <tr>
              <td className='compactHeader'>
                <>QuickBooks:</>
              </td>
              <td>
                {qbSyncData.costToDate ? formatMoney(qbSyncData.costToDate) : '-'}
              </td>
              <td>
                {qbSyncData.invoiceToDate ? formatMoney(qbSyncData.invoiceToDate) : '-'}
              </td>
              <td>
                {qbSyncData.estimateIncome ? formatMoney(qbSyncData.estimateIncome) : '-'}
              </td>
              <td>
                {qbSyncData.estimateCost ? formatMoney(qbSyncData.estimateCost) : '-'}
              </td>
              <td>
                {qbSyncData.outstanding ? formatMoney(qbSyncData.outstanding) : '-'}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
    
  }

  return (
    <div className=''>
      <div className='pDetail'>

        

        {(canViewProfit||canViewContract) && !isWarrantyService && (<>
          <h6>Dates and Dollars</h6>

          {(canViewProfit||canViewContract) && !isWarrantyService && renderQBdata()}

          <div className='pDetail'>
            <div className='pDetailTableBox compactTable'>
              <table className={tableClassName}>
                <tbody>
                  <tr>
                    <td className='compactHeader'>
                      <strong>Dollars</strong>
                    </td>
                    {isActivePlusStage && <td><strong>Original Contract</strong></td>}
                    {isActivePlusStage && <td><strong>Current Contract</strong></td>}
                    <td><strong>{isActivePlusStage?(isDeliveredVisible?'Actual':'Actual/Projected'):(isServiceProject?'Service Dates':'Current Bid')}</strong></td>
                    {isActivePlusStage && <td><strong>Variance</strong></td>}
                  </tr>
                  {canViewContract && (
                    <tr>
                      <td className='compactHeader'>
                        <>Contract Amount ($):</>
                      </td>
                      {isActivePlusStage && <td>
                        {projectData &&
                          formatMoney(projectData.originalContractAmount)}
                      </td>}
                      {isActivePlusStage && <td>
                        {projectData &&
                          formatMoney(projectData.currentContractAmount)}
                      </td>}
                      <td>
                        {projectData &&
                          formatMoney(projectData.currentContractAmount)}
                      </td>
                      {isActivePlusStage && <td>
                        {projectData &&
                          varianceForDollars(projectData.currentContractAmount, projectData.originalContractAmount)}
                      </td>}
                    </tr>
                  )}
                  {canViewProfit && (
                    <tr>
                      <td className='compactHeader'>
                        <>Profit (Gross $):</>
                      </td>
                      {isActivePlusStage && <td>
                        {projectData && formatMoney(projectData.originalGrossProfit)}
                      </td>}
                      {isActivePlusStage && <td>
                        {projectData && formatMoney(projectData.currentContractProfit)}
                      </td>}
                      <td>
                        {projectData && formatMoney(projectData.currentGrossProfit)}
                      </td>
                      {isActivePlusStage && <td>
                        {projectData &&
                          varianceForDollars(projectData.currentGrossProfit, projectData.currentContractProfit)}
                      </td>}
                    </tr>
                  )}
                  {canViewProfit && (
                    <tr>
                      <td className='compactHeader'>
                        <>Profit (Gross %):</>
                      </td>
                      {isActivePlusStage && <td>
                        {projectData && formatPercent(projectData.originalGrossProfit/projectData.originalContractAmount*100)}
                      </td>}
                      {isActivePlusStage && <td>
                        {projectData && formatPercent(projectData.currentContractProfit/projectData.currentContractAmount*100)}
                      </td>}
                      <td>
                        {projectData && formatPercent(projectData.currentGrossProfit/projectData.currentContractAmount*100)}
                      </td>
                      {isActivePlusStage && <td>
                        {projectData &&
                          formatPercent((projectData.currentGrossProfit-projectData.currentContractProfit)/projectData.currentContractProfit*100)}
                      </td>}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {canEditBid && !isActivePlusStage && <a className='editBtn' onClick={() => props.onManageBidModal()}>
              <i className='fa fa-pencil' aria-hidden='true'></i>
            </a>}
            {canEditWIP && isActivePlusStage && <a className='editBtn' onClick={() => props.onUpdateWIPModal()}>
              <i className='fa fa-pencil' aria-hidden='true'></i>
            </a>}
          </div>
        </>)}

        <div className='pDetailTableBox compactTable'>
          <table className={tableClassName}>
            <tbody>
              <tr>
                <td className='compactHeader'>
                  <strong>Dates</strong>
                </td>
                {isActivePlusStage && <td><strong>Original Contract</strong></td>}
                <td><strong>{isActivePlusStage?'Current Contract':(isServiceProject?'Service Dates':'Current Bid')}</strong></td>
                {isActivePlusStage &&<td><strong>{isDeliveredVisible?'Actual/Projected':'Actual/Projected'}</strong></td>}
                {isActivePlusStage && <td><strong>Variance</strong></td>}
              </tr>
              {isBidDueVisible && <tr>
                <td className='compactHeader'>
                  <>Bid Due:</>
                </td>
                <td>
                  {projectData && projectData.bidDue
                    ? formatBidDueDate(projectData.bidDue)
                    : '-'}
                </td>
              </tr>}
              {isBidSubmittedVisible && <tr>
                <td className='compactHeader'>
                  <>Bid Submitted:</>
                </td>
                <td>
                  {projectData?.bidSubmittedDate
                    ? formatBidDueDate(projectData.bidSubmittedDate)
                    : '-'}
                </td>
              </tr>}
              <tr>
                <td className='compactHeader'>
                  <>Start Date:</>
                </td>
                {isActivePlusStage && <td>
                  {projectData?.baselineStartDate ? formatDateMMDDYY(projectData.baselineStartDate) : '-'}
                </td>}
                {isActivePlusStage && <td>
                  {projectData?.baselineStartDate ? formatDateMMDDYY(projectData.baselineStartDate) : '-'}
                </td>}
                <td>
                  {projectData?.startDate ? (isActivePlusStage?formatDateMMDDYY(projectData.startDate):formatDate(projectData.startDate)) : '-'}
                </td>
                {isActivePlusStage && <td>
                  {projectData?.startDate?.iso && projectData?.baselineStartDate?.iso
                    ? varianceForRedDates(
                      daysBetweenDates(projectData.startDate.iso, projectData.baselineStartDate.iso)
                    )
                    : '-'}
                </td>}
              </tr>
              <tr>
                <td className='compactHeader'>
                  <>End Date:</>
                </td>
                {isActivePlusStage && <td>
                  {projectData?.baselineEndDate ? formatDateMMDDYY(projectData.baselineEndDate) : '-'}
                </td>}
                {isActivePlusStage && <td>
                  {projectData?.currentBaselineEndDate ? formatDateMMDDYY(projectData?.currentBaselineEndDate) : '-'}
                </td>}
                <td>
                  {isDeliveredVisible?  (projectData?.deliveredDate ? formatDateMMDDYY(projectData?.deliveredDate) : '-'):(projectData?.endDate ? (isActivePlusStage?formatDateMMDDYY(projectData.endDate):formatDate(projectData.endDate)) : '-')}
                </td>
                {isActivePlusStage && <td>
                  {projectData?.currentBaselineEndDate?.iso && projectData?.endDate?.iso
                    ? varianceForRedDates(
                      daysBetweenDates((isDeliveredVisible?(projectData?.deliveredDate?.iso):projectData.endDate.iso),projectData.currentBaselineEndDate.iso)
                    )
                    : '-'}
                </td>}
              </tr>
              <tr>
                <td className='compactHeader'>
                  <>Duration:</>
                </td>
                {isActivePlusStage && <td>
                  {projectData?.baselineEndDate?.iso ?  daysBetweenDates(projectData.baselineEndDate.iso, projectData.baselineStartDate?.iso) +'d': '-'}
                </td>}
                {isActivePlusStage && <td>
                  {projectData?.currentBaselineEndDate?.iso ? daysBetweenDates(projectData.currentBaselineEndDate.iso, projectData.baselineStartDate?.iso) +'d' : '-'}
                </td>}
                <td>
                {projectData?.endDate?.iso ? daysBetweenDates(projectData.endDate.iso, projectData.startDate?.iso) +'d' : '-'}
        
                </td>
                {isActivePlusStage && <td>
                  {projectData?.currentBaselineEndDate?.iso && projectData?.baselineEndDate?.iso && projectData?.endDate?.iso && projectData?.endDate?.iso
                    ? varianceForRedDates(
                      daysBetweenDates(projectData.endDate.iso, projectData.startDate.iso)-daysBetweenDates(projectData.currentBaselineEndDate.iso, projectData.baselineStartDate.iso)
                    )
                    : '-'}
                </td>}
              </tr>
            
            </tbody>
          </table>
        </div>

        {isActivePlusStage && <div className='pDetailTableBox compactTable'>
          <table className={tableClassName}>
            <tbody>
              <tr>
                <td className='compactHeader'>
                  <strong>Post-Construction Dates</strong>
                </td>
                <td><strong>Term</strong></td>
                <td><strong>Date</strong></td>

                {isActivePlusStage && <td><strong>&nbsp;</strong></td>}
              </tr>
              {isInWarranty && <tr>
                <td className='compactHeader'>
                  <>Closeout Date:</>
                </td>
                <td>
                  {closeoutDateValue(projectData)}
                </td>
                <td>
                  {projectData?.closeoutDate ? formatDate(projectData.closeoutDate) : '-'}
                </td>
               

              </tr>}
              {isActivePlusStage && <tr>
                <td className='compactHeader'>
                  <>Lien Expiration:</>
                </td>
                <td>
                  {projectData && projectData.lienPeriod ? projectData.lienPeriod + ' days' : '-'}
                </td>
                <td>
                  {projectData?.lienExpiration?.iso
                    ? formatDate(projectData.lienExpiration.iso)
                    : '-'}
                </td>
              </tr>}
              {isActivePlusStage && <tr>
                <td className='compactHeader'>
                  <>Warranty Expiration:</>
                </td>
                <td>
                  {projectData && projectData.warrPeriod
                    ? projectData.warrPeriod + ' month(s)'
                    : '-'}
                </td>
                <td>
                  {projectData?.warrExpiration?.iso
                    ? formatDate(projectData.warrExpiration.iso)
                      : '-'}
                </td>
                {isActivePlusStage && <td>&nbsp;</td>}
              </tr>}
              {isDeliveredVisible && 
              <tr>
                <td className='compactHeader'>
                  <>Follow Up Date:</>
                </td>
                <td>
                  {accountInfo && accountInfo.followUpPeriod
                    ? accountInfo.followUpPeriod + ' days'
                    :  '-'}
                </td>
                <td>
                  {props.props && props.props.allMessages && props.props.allMessages[0] && props.props.allMessages[0].createdAt
                    && props.props.allMessages[0].followUpStatus === true
                    ? formatDate(props.props.allMessages[0].createdAt)
                    : projectData && projectData.followUpDone && projectData.followUpDone.iso
                      ? formatDate(projectData.followUpDone.iso)
                      : <Link to={{ pathname: `/project-details/` + state.objectId+'/notes/followUp' }}> Add Follow-Up</Link>}
                </td>
                {isActivePlusStage && <td>&nbsp;</td>}
              </tr>}
            </tbody>
          </table>

        </div>}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    allMessages: state.message.allMessage,
    projectData: state.project.projectData,
    allProjectStatus: state.jobStatusCode,
    reasonCodes: state.reasonCode,
    jobScope: state.jobScope,
    changeOrder: state.changeOrder,
    fetchRequest: state.project.fetchRequest,
    accountInfo: selectGeneralInfo(state),
    allJobStatusCodes: state.jobStatusCode.allJobStatus,
  };
}

export default withRouter(
  connect(mapStateToProps)(DatesAndDollars)
);
